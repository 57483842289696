var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'إرسال رسائل جديدة',
    icon: 'SendIcon',
    route: 'send-devices',
  },
  {
    title: 'إرسال من ملف Excel',
    icon: 'FileIcon',
    route: 'send-excel',
  },
  {
    title: 'الإشتراكات والباقات',
    route: 'subscribe',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'الأجهزة / الأرقام',
    route: 'devices',
    icon: 'SmartphoneIcon',
  },
  {
    title: 'أرشيف الإرسال',
    route: 'archive',
    icon: 'ArchiveIcon',
  },
  {
    title: 'الأرقام والمجموعات',
    icon: 'UsersIcon',
    children: [
      {
        title: 'إضافة / استيراد',
        route: 'contacts-add',
      }, {
        title: 'استعراض الارقام',
        route: 'contacts',
      }, {
        title: 'استعراض المجموعات',
        route: 'groups',
      }, {
        title: 'الارقام المكررة',
        route: 'repeated',
      }
    ],
  },
  {
    title: 'الردود التلقائية',
    route: 'replies',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'أوكتو واتس (للشركات)',
    route: 'octo',
    icon: 'CommandIcon',
  },
  {
    title: 'نظام المسوقين',
    icon: 'LinkIcon',
    g: 'resller',
    children: [
      {
        icon: "SendIcon",
        title: 'دعوة عبر الرابط',
        route: 'reseller/share',
      }, {
        icon: "DollarSignIcon",
        title: 'مستحقاتي / الرصيد',
        route: 'reseller/report',
      },
    ],
  },
  {
    title: 'رسائل SMS البديلة',
    route: 'sms',
    icon: 'CornerUpLeftIcon',
  },
  {
    title: 'الإعدادات',
    icon: 'SettingsIcon',
    g: 'settings',
    children: [
      {
        title: 'البيانات الشخصية',
        route: 'settings/profile',
      }, {
        title: 'تصحيح الإرقام المرسلة',
        route: 'settings/process-numbers',
      },
    ],
  },
  {
    title: 'API للمطورين',
    route: 'api',
    icon: 'CodeIcon',
  },
  {
    title: 'تواصل معنا / التسويق',
    route: 'contact',
    icon: 'PhoneIcon',
  },
  {
    title: 'شرح الاستخدام',
    href: 'https://youtu.be/gVRa8PnfpjU',
    icon: 'VideoIcon',
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if (e.g == "resller") {
    if (user?.reseller_mode) {
      arr2.push(e)
    }
  } else {
    arr2.push(e)
  }

})


export default arr2
