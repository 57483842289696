<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="border g" @click="$router.push('/subscribe')">
        <span v-if="!user.subscribe_ended">
          <span class="text-danger" v-if="user.plan == 0"> اشتراك تجريبي </span>
          <span class="text-success" v-if="user.plan != 0">
            اشتراك فعال حتى {{ user.exdate }}
          </span>
        </span>
        <span v-if="user.subscribe_ended">
          <span class="btn btn-danger"> انتهى اشتراكك! </span>
        </span>
      </div>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.phone }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          @click="$router.push('/logout')"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>تسجيل الخروج</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    setTimeout(() => {
      if (this.user.subscribe_ended) {
        $("#c-subscribe-ended").click();
      }
    }, 1000);
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
